function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const ROOTS_DASHBOARD = '/';

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  otp: '/otp',
  choose_company: '/choose_company',
};

export const PATH_DASHBOARD = {
  root: '/',
  SearchResults: { root: (searchValue: any) => `/search/${searchValue}` },
  MarketPlace: {
    root: '/',
    view: (name: string) => `/products/${name}`,
  },
  Controls: '/Controls',
  Orders: {
    all:'/orders',
    root: '/orders/all',
    view: (id: string) => path('/orders/all', `/${id}`),
    WfordersRoot: '/orders/wforders',
    WfordersView: (id: string) => path('/orders/wforders', `/${id}`),
  },
  categories: {
    root: '/categories',
    sections: '/categories/section',
    CategoriesProducts: '/Categories/Products.Category',
    EssentialsPack: '/categories/EssentialsPack',
    Store: '/categories/store',
  },
  reports: '/reports',
  invoices: {
    root: '/invoices',
    view: (id: string) => path('/invoices', `/${id}`),
    payment: (id: string) => path('/invoices/payment', `/${id}`),
  },
  ReorderingLists: {
    root: '/ReorderingLists',
    addForm: '/ReorderingLists/AddList',
    view: (id: string) => path('/ReorderingLists', `/${id}`),
  },
  SmartStock: {
    root: '/smart-stock-list',
    addForm: '/smart-stock-list/add-list',
    view: (id: number) => path('/smart-stock-list', `/${id}`),
  },
  UserProfile: '/UserProfile',
  CompanyTabs: '/CompanyProfile',
  CustomerService: '/CustomerService',
  CartDetails: '/cart',
  ConfirmationOrderScreen: '/order_confirmation',
  PrivacyPolicy: '/privacypolicy',
  ProductNotFound: '/productnotfound',
  Notifications: '/notifications',
  Accessdenied: '/Accessdenied',
  Catalog: '/catalog',
  RFQ: {
    root: '/request_for_quotation',
    RFQForm: '/request_for_quotation/create',
    view: (id: string) => path('/request_for_quotation', `/${id}`),
    chat: '/request_for_quotation/chat',
    payment: (id: string) => path('/request_for_quotation/payment', `/${id}`),
  },
  AnalyticsTagsList: '/AnalyticsTagsList',
  MyCatalog: {
    root: '/catalog',
  },
  SubscriptionManagement: {
    root: '/subscription-management',
    addNew: '/subscription-management/add',
  },
  // MOBILE VIEW
  MobileSettings: '/settings',
  AddressListMobileView: '/address',
  UserProfileMobileView: '/profile',
  CompanyListMobileView: '/companies',
  FinancialInformationMobile: '/financial',
  CategoriesMobileView: '/categories',
  AllProduct: '/all-products'

};

export const ANONYMOUS_PATH_DASHBOARD = {
  root: '/',
  SearchResults: { root: (searchValue: any) => `/search/${searchValue}` },
  MarketPlace: {
    root: '/',
    view: (name: string) => `/products/${name}`,
  },
  categories: {
    root: '/categories',
    sections: '/categories/section',
    CategoriesProducts: '/Categories/Products.Category',
    EssentialsPack: '/categories/EssentialsPack',
    Store: '/categories/store',
  },
  ProductNotFound: '/productnotfound',
  CategoriesGuestMobileView: '/categories',
  MobileSettings: '/settings',
  AllProductGuest: '/all-products',
};
