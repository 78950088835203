import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgColor from '../../../components/svg-color';

const icon = (name: string, type: string = 'svg') => (
  <SvgColor src={`/assets/icons/navbar/${name}.${type}`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_menu_item'),
  orders: icon('ic_cart'),
  invoices: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  reports: icon('ic_analytics'),
  booking: icon('ic_booking'),
  chat: icon('ic_chat'),
  customer: icon('ic_customer'),
  rfq: icon('ic_label'),
  tags: icon('tags', 'png'),
  catalog: icon('ic_catalog'),
  smart_stock: icon('inventory-24', 'png'),
};

const navConfig = [
  {
    subheader: 'home',
    items: [
      { title: 'Marketplace', path: PATH_DASHBOARD.root, icon: ICONS.ecommerce, deep: false },
      {
        title: 'Categories',
        path: PATH_DASHBOARD.categories.root,
        icon: ICONS.dashboard,
        isCategoryItem: true,
        children: [],
      },
      {
        title: 'my_catalog',
        path: PATH_DASHBOARD.MyCatalog.root,
        icon: ICONS.catalog,
        isMyCatalogItem: true,
      },
      { title: 'Orders', 
        path: PATH_DASHBOARD.Orders.all, 
        icon: ICONS.orders,
        children: [
          { title: 'Orders', path: PATH_DASHBOARD.Orders.root},
          { title: 'waiting_approval', path: PATH_DASHBOARD.Orders.WfordersRoot}
        ],
       },
      {
        title: 'RequestForQuotation',
        path: PATH_DASHBOARD.RFQ.root,
        icon: ICONS.rfq,
        isRfqItem: true,
        caption: '(Beta)',
      },
      { title: 'Invoices', path: PATH_DASHBOARD.invoices.root, icon: ICONS.invoices },
    ],
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'ReorderingLists',
        path: PATH_DASHBOARD.ReorderingLists.root,
        icon: ICONS.calendar,
      },
      {
        title: 'smart_stock',
        path: PATH_DASHBOARD.SmartStock.root,
        icon: ICONS.smart_stock,
        hasInventory: true,
      },
      {
        title: 'subscriptions',
        path: PATH_DASHBOARD.SubscriptionManagement.root,
        icon: ICONS.invoices,
      },
      {
        title: 'Reports',
        path: PATH_DASHBOARD.reports,
        icon: ICONS.reports,
      },
      {
        title: 'AnalyticsTags',
        path: PATH_DASHBOARD.AnalyticsTagsList,
        icon: ICONS.tags,
        roles: process.env.REACT_APP_ALLOW_TAGS === 'true' ? undefined : ['tags'],
      },
    ],
  },
  {
    subheader: 'Account',
    items: [
      { title: 'UserProfile', path: PATH_DASHBOARD.UserProfile, icon: ICONS.user },
      { title: 'CompanyProfile', path: PATH_DASHBOARD.CompanyTabs, icon: ICONS.booking },
    ],
  },
  {
    subheader: 'Help',
    items: [
      {
        title: 'CustomerService',
        path: 'https://wa.me/966557001599',
        icon: ICONS.customer,
      },
    ],
  },
];

export default navConfig;
